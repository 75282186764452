.order {
    display: flex;
    flex-flow: row nowrap;
}

.full {
    width: 100%;
    flex-basis: 100%;
    padding: 10px;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
}


.options {
    line-height: 40px;
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 5px;
}

    .options button {
        margin-right: 2px;
    }

.quantiyrow {
    display: flex;
}

.quantiybox {
    width: 70px;
    text-align: center;
}

    .quantiybox input {
        text-align: center;
        /*width: 40px !important;*/
        /**/
        /*font-size: 18px;*/
        padding-left: 4px !important;
        padding-right: 8px !important;
    }

.title {
    text-align: center;
}


.info {
}
