.row {
    /*display: flex;
    flex-flow: row nowrap;
    min-height: 40px;*/
    border-bottom: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    padding: 5px 5px 5px 10px;
    min-height: 51px;
    font-size: 16px;
}

    .row:hover {
        background-color: #EBEFF2 !important;
    }

    .row a {
        padding-left: 4px;
        padding-right: 4px;
    }

.comment {
    /*line-height: 30px;*/
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
}

.headline {
    padding-left: 15px;
}

.image {
    cursor: pointer;
    /*height: 50px;*/
}

    .image img {
        padding-right: 10px;
        max-height: 40px;
        cursor: pointer;
    }

.video {
    cursor: pointer;
    /*height: 50px;*/
}

    .video img {
        padding-right: 10px;
        max-height: 40px;
        cursor: pointer;
    }

.full {
    width: 100%;
    flex-basis: 100%;
    padding: 10px;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;
}

.document {
    cursor: pointer;
}

.contract {
    cursor: pointer;
}

.even {
    background-color: #f9f9f9 !important;
}

.loading {
    max-height: 60px;
}
