@import '~antd/dist/reset.css';
@import 'messenger.css';
@import 'order.css';

body {
    /*-webkit-tap-highlight-color: transparent;*/
}

a {
    color: #0366d6;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.List {
    width: 100%;
    border: 1px solid #DDD;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 25px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.placeholder {
    display: inline-block;
    height: 1em;
    background-color: #DDD;
}

.cacheButtonAndCountRow {
    display: flex;
    align-items: center;
}

.button {
    flex: 0 0 auto;
    background-color: #4db6ac;
    color: #fff;
    appearance: none;
    border: none;
    padding: .5em 1em;
    border-radius: .35em;
    font-size: 1em;
}

.cacheCountRow {
    flex: 1 1 auto;
    text-align: right;
    color: #bdbdbd;
    font-size: .75em;
    font-weight: 100;
}



.editable-row .ant-form-explain {
    position: absolute;
    font-size: 12px;
    margin-top: -4px;
}

.grid {
    display: flex;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    /*padding:10px;*/
    /*margin-bottom:100px;*/
}



    .grid:focus {
        outline: none !important;
    }

.ReactVirtualized__Table {
    outline: none !important;
}

*:focus {
    outline: none;
}



body,
html {
    margin: 0;
    height: 100vh;
    max-height: 100%;
    color: #000000;
}

body {
    display: flex;
    flex-flow: column;
    height: 100vh;
    /*font-family: 'Jost','Noto Sans', serif;*/
}

.top {
    min-height: 1px;
    border-bottom: 1px solid #eee;
}

.bottom {
    min-height: 1px;
}

.left {
    width: 250px;
    /*    overflow: auto;*/
    position: relative;
    overflow-y: scroll;
}

.right {
    flex: 1;
    overflow: hidden;
}

.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-flow: row nowrap;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}


.content {
    display: flex;
    overflow: hidden;
}



.spin-container {
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}

#root {
    flex: 1 1 auto;
    height: 100vh;
}



.data-row {
    clear: both;
    width: 100%;
    display: flex;
    flex-direction: row;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #eee;
}

::-webkit-scrollbar-thumb {
    background: #aaa;
}



.pointer {
    cursor: pointer;
}

.bold {
    font-weight: bold;
    /*    color: #000000 !important;*/
    /*    background-color: #eee;*/
}

div.slider {
    /*display: none;*/
}

.vendor-name {
    font-size: 12px;
    color: #666666 !important;
}

div.order-input {
    width: 50px;
    height: 50px;
    text-align: center;
    border: solid 1px rgb(217, 217, 217);
    vertical-align: central;
    text-align: center;
    background-color: #fff;
    font-size: 18px;
    line-height: 50px;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
}


input.order-quantity {
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 18px;
}

.order-details, .order-details td {
    background-color: #eee !important;
}

.order-details-quantity, .order-details-quantity td {
    background-color: #eee !important;
    border-top: none !important;
    padding: 4px !important;
}

.delivery-title {
    line-height: 40px;
}


.dataTables_filter {
    /*    padding-top: 5px;*/
    padding-right: 5px;
}

    .dataTables_filter input {
        border: 1px solid #cccccc !important;
    }


.dataTables_info {
    padding-left: 10px;
    padding-top: 4px;
}

.order-status {
    text-align: center;
    width: 100%;
    line-height: 24px;
    font-size: 15px;
}

.order-vendor {
    color: #106CCE !important;
}


.order-details-controls {
    display: flex;
    flex-wrap: nowrap;
}

.order-details-controls-row {
    width: 60px;
    text-align: center;
}

.order-details-options {
    line-height: 40px;
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 5px;
}

    .order-details-options button {
        margin-right: 2px;
    }




.flex {
    display: flex;
}

.push {
    margin-left: auto;
}


.wrap {
    word-break: break-all !important;
    white-space: normal !important;
    display: table-cell;
}




/** {
    animation-duration: 0s !important;
}*/

.center-align {
    text-align: center !important;
}


.temp-nav {
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 30px;
    left: 0;
    text-align: center;
}



.loading-window {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
}

.hidden {
    display: none !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}



.padding-right-5 {
    padding-right: 5px;
}


.link:hover {
    text-decoration: underline;
    color: #1890ff;
}

.ant-btn-link:hover {
    text-decoration: underline !important;
}



.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    flex-direction: column;
}

    .loading .error {
        padding-top: 20px;
    }

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}



.responsive {
    max-width: 100%;
    max-height: 100%;
}



.responsive-100-w {
    max-width: 100%;
}





.instructions-page {
    padding: 20px;
}

.meeting-schedule .ant-modal-confirm-content {
    margin: 0 !important;
}


.padding-left-10 {
    padding-left: 10px;
}




/*MODAL*/
.ant-modal-root {
    z-index: 99999999999;
}

.ant-modal-body video {
    max-width: 480px;
}

.modal-content {
    max-width: 900px;
    margin: auto;
}


.modal-window {
    width: 100%;
    height: 100%;
    max-width: 100% !important;
}

    .modal-window .ant-modal-header {
        padding: 10px !important;
        margin-bottom: 0px !important;
    }

    .modal-window .ant-modal-content {
        min-height: 100%;
        padding: 0 !important;
        border-radius: 0 !important;
    }

    .modal-window .ant-modal-body {
        padding: 0 !important;
        height: calc(100vh - 50px) !important;
    }

    .modal-window .ant-modal-close {
        width: 110px !important;
    }

.contract-content {
    height: calc(100vh - 50px) !important;
    display: flex;
}

    .contract-content h2 {
        margin-bottom: 0 !important;
    }


.order-modal {
    width: 100%;
    height: 100%;
}

    .order-modal .ant-modal-content {
        min-height: 100%;
    }

    .order-modal .ant-modal-body {
        padding: 5px !important;
        height: calc(100vh - 110px);
    }

.media-window {
    width: 100%;
    height: 100%;
}

    .media-window .ant-modal-content {
        min-height: 100%;
    }

    .media-window .ant-modal-body {
        padding: 5px !important;
        height: calc(100vh - 110px);
    }



.announcements-modal .ant-modal-body {
    padding: 20px !important;
    height: calc(100vh - 110px);
}



.splash-content img {
    max-width: 100%;
}




.ant-modal-close-x {
    width: 120px !important;
}

.fixed-title {
    position: fixed;
    height: 50px;
    background-color: #fff;
    padding: 5px;
    margin: 0;
    width: inherit;
    z-index: 999;
}



.meetings-page aside {
    border-right: 1px solid #f0f0f0;
}


.full-height {
    height: calc(100vh - 55px);
    display: flex;
}

.meetings-page {
}


.available-vendors {
    padding: 0 10px 10px 10px;
}

    .available-vendors img {
        max-width: 100px;
        max-height: 100px;
    }

    .available-vendors ul {
        margin: 0;
        padding: 0;
    }


    .available-vendors li {
        list-style-type: none;
        margin: 0;
        padding: 5px;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        justify-content: center;
        text-align: center;
    }

        .available-vendors li.active, .available-vendors li:hover {
            background-color: #e6f7ff !important;
        }


.schedule-list {
    list-style: none;
    margin-top: 50px !important;
}

ul.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}


.my-schedule {
}

    .my-schedule h3 {
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #e0e0e0;
    }


    .my-schedule button {
        font-size: 18px;
        padding-left: 0;
    }


.white-background {
    background-color: #fff;
}


.modal .ant-modal-body {
    padding: 5px 5px 0 5px !important;
}



.schedule-time {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.schedule-time-label {
    width: 80%;
}

.schedule-time-remove {
    width: 20%;
    vertical-align: top;
    text-align: right;
}







.vendor-controls {
    margin: 5px 0;
    padding: 5px;
}


    .vendor-controls button {
        padding: 0px 8px;
    }

    .vendor-controls .ant-btn > .anticon + span {
        margin-bottom: 0px;
        height: 30px;
        vertical-align: middle;
    }




#menu {
    top: 0;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 120px;
    height: 60px;
    border-radius: 0 0 110px 110px;
    background-color: #e6f7ff;
    margin-top: 0px;
    z-index: 999;
    padding: 8px 5px;
    text-align: center;
    font-size: 16px;
    vertical-align: middle;
    cursor: pointer;
}

    #menu .anticon + span {
        height: 30px;
        line-height: 30px;
        vertical-align: top;
        padding-left: 5px;
    }

.main-menu .ant-drawer-header {
    height: 80px;
}

.main-menu .ant-drawer-title {
    text-align: center;
    font-size: 28px;
    padding-top: 15px;
}

.main-menu .ant-drawer-body {
}

.main-menu span.anticon {
    font-size: 30px;
    margin-top: 10px;
}



.main-menu-content {
    max-width: 900px;
    margin: auto;
    text-align: center;
}

    .main-menu-content button {
        font-size: 20px;
        height: 50px !important;
    }

    .main-menu-content .ant-btn > .anticon + span {
        margin-bottom: 12px;
        height: 35px;
        vertical-align: middle;
    }


.page-content {
    overflow: auto;
}

    .page-content h1 {
        font-size: 30px;
        text-align: center;
        height: 60px;
        background-color: #f0f0f0;
        padding: 5px;
        margin: 0;
        z-index: 998;
        position: fixed;
        left: 0;
        right: 15px;
        top: 51px;
    }

    .page-content .header {
        text-align: center;
        height: 60px;
        background-color: #f0f0f0;
        padding: 5px;
        margin: 0;
        z-index: 998;
        position: fixed;
        left: 0;
        right: 15px;
        top: 51px;
        display: flex;
    }

        .page-content .header .left {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-transform: uppercase;
            cursor: pointer;
        }

        .page-content .header .center {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
        }

        .page-content .header .right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-transform: uppercase;
            cursor: pointer;
        }


    .page-content .content-html {
        margin-left: auto;
        margin-right: auto;
        margin-top: 80px;
        max-width: 1000px;
        padding-bottom: 40px;
    }


.available-times {
    margin-top: 10px;
    margin-left: 20px;
}

    .available-times .header {
        padding-bottom: 10px;
        text-align: center;
    }

    .available-times img {
        text-align: center;
        max-height: 100px;
    }


    .available-times h2 {
        display: block;
    }

    .available-times h3 {
        margin: auto;
        font-size: 20px;
        padding-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 5px;
    }

    .available-times h4 {
        margin: auto;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
        padding-bottom: 5px;
    }

    .available-times .day {
        margin-bottom: 10px;
    }



.announcements-list {
    margin: 0;
    padding: 0;
}

    .announcements-list li {
        list-style-type: none;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 20px;
        padding: 10px;
    }


    .announcements-list .announcement-content-html {
    }

    .announcements-list .date {
        text-align: right;
        opacity: 0.5;
    }


.content-full-html {
    margin-top: 80px;
}


    .content-full-html img {
        max-width: 100%;
    }

.link-button {
    cursor: pointer;
}


.lobby {
    text-align: center;
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
}

    .lobby img {
        /*max-width: 100%;
        height: auto;*/
    }



    .lobby .title {
    }

    .lobby .announcements-list {
        padding: 10px;
        background-color: #fff;
        min-height: 300px;
        cursor: pointer;
    }

        .lobby .announcements-list .title {
            color: #0366d6;
            line-height: 30px;
            text-transform: uppercase;
            cursor: pointer;
        }

    .lobby .announcements-items {
        text-align: left;
        cursor: pointer;
    }

    .lobby .announcements-item {
        text-align: left;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
    }


.booths {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

    .booths .booth {
        flex: 0 1 300px;
        padding: 10px;
        margin: 10px;
        cursor: pointer;
        min-height: 200px;
        display: grid;
        place-items: center;
        /*border-bottom: 1px solid #f0f0f0;*/
        border: 1px solid #f0f0f0;
        background-color: #f0f0f0;
    }

        .booths .booth:hover {
            background-color: #e6f7ff !important;
        }

    .booths .title {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
    }

    .booths img {
        max-height: 150px;
        max-width: 250px;
    }



/*
ant-table [vt] > table > .ant-table-tbody > tr > td {
    padding: 8px;
}*/



.booth-page {
    margin-top: 60px;
    text-align: center;
}

    .booth-page img {
        max-width: 100%;
        height: auto;
    }

.button-list {
    color: #fff;
    display: flex;
    justify-content: center;
    background-color: #3E000F;
    padding: 5px;
}

    .button-list div {
        border: solid 1px #fff;
        color: #fff;
        padding: 2px 5px;
        margin: 0 2px 0 2px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 10px;
    }


.full-height-page {
    margin-top: 60px;
    height: calc(100vh - 112px);
    display: flex;
    width: 100%;
}




h1.page-header {
    font-size: 30px;
    text-align: center;
    height: 60px;
    line-height: 60px;
    width: 100%;
    background-color: #f0f0f0;
    padding: 0 5px;
    margin: 0;
}

.customer-list {
    padding: 10px;
}

    .customer-list .customer {
        padding: 10px;
        border-bottom: solid 1px rgb(238, 238, 238);
    }

        .customer-list .customer .title {
            font-size: 24px;
        }


.item-loading {
    height: 30px;
}


.customer-controls {
    margin: 5px 0;
    padding: 5px;
}


    .customer-controls button {
        padding: 0px 8px;
    }

    .customer-controls .ant-btn > .anticon + span {
        margin-bottom: 0px;
        height: 30px;
        vertical-align: middle;
    }



ul.simple-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

    ul.simple-list li {
        list-style-type: none;
        margin: 5px;
    }


#order-grid {
    /*border-bottom: 1px solid #d9d9d9;*/
    border-top: 1px solid #d9d9d9;
    /*height: calc(100vh - 100px);*/
    display: flex;
    width: 100%;
    height: 100%;
}


.video-row img {
    max-height: 60px;
}


.grid-footer {
    height: 100px;
}


.icon {
    cursor: pointer;
    opacity: 0.6;
    text-align: center;
}

    .icon:hover {
        opacity: 1;
    }


.my-schedule-title {
    position: fixed;
    height: 60px;
    background-color: #fff;
    padding: 5px;
    margin: 0;
    width: inherit;
    z-index: 999;
}

    .my-schedule-title h2 {
        margin-bottom: 0 !important;
    }

.center-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.item-no {
    display: flex;
    width: 100%;
    flex-direction: column;
}

    .item-no img, .item-no div {
        align-self: center;
    }



.broker-vendor-list {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 5px;
}


.ant-select-item-group {
    font-weight: bold !important;
    border-bottom: solid 1px rgb(238, 238, 238);
    color: #000000;
    text-transform: uppercase;
}

.ant-select-item-option-grouped {
    padding-left: 16px !important;
}


.history-column {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.ant-layout .ant-layout-footer {
    padding: 0 !important;
} 

.ant-layout-header {
    background-color: #fff !important;
}

.ant-input-number-input {
    text-align: center !important;
}
