
.item {
    border-bottom: 1px solid #eee;
    list-style-type: none;
    margin: 0;
    padding: 10px;
    display: flex;
    width: 100%;
    /*min-height: 128px;*/
}

    .item:hover {
        background-color: #e6f7ff !important;
    }


    .item img {
        max-width: 100px;
        max-height: 100px;
    }

.logo {
    width: 150px;
    text-align: center;
}

    .logo span {
        width: 100%;
        height: 100%;
        background-color: #eee;
    }

.meta {
    flex: 1;
}

.title {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.vendor {
    width: 50%;
    font-size: 24px;
    cursor: pointer;
}

.broker {
    font-size: 16px;
    width: 50%;
    text-align: right;
}

.chain {
    font-size: 16px;
    width: 50%;
    text-align: right;
}

.category {
    font-size: 14px;
}


.customer {
    padding-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: column;
}

    .customer i {
        min-width: 200px;
        font-size: 18px;
    }

.accountno {
    opacity: 0.8;
    padding-left: 20px;
}

.customername {
    width: 100%;
}

.locations {
    width: 100%;
    opacity: 0.8;
}

.salesrep {
    opacity: 0.8;
    font-size: 12px;
    padding-right: 20px;
    min-width: 200px;
}


.loading {
    opacity: 0.5;
}

.bold{
    font-weight:bold;
}
.even {
    background-color: #f9f9f9 !important;
}

.brokervendors{
    padding-right:50px;
}