.toggle {
    width: 60px;
    height: 60px;
    background-color: #4e8cff;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    right: 25px;
    bottom: 25px;
    border-radius: 50%;
    box-shadow: none;
    transition: box-shadow 0.2s ease-in-out;
    z-index: 998;
    cursor: pointer;
}

    .toggle:before {
        content: '';
        position: relative;
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        transition: box-shadow 0.2s ease-in-out;
    }

    .toggle:hover {
        box-shadow: 0 0px 27px 1.5px rgba(0,0,0,0.2);
    }

.close {
    position: fixed;
    right: 35px;
    bottom: 35px;
    font-size: 40px;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.open {
    opacity: 0;
    position: fixed;
    right: 35px;
    bottom: 35px;
    font-size: 40px;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.opened .open {
    transform: rotate(-180deg);
    opacity: 1;
}

.opened .close {
    transform: rotate(-180deg);
    opacity: 0;
}

.opened:before {
    box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.expanded {
    display: none;
}

.count {
    position: absolute;
    top: -10px;
    right: 0;
    width: 25px;
    height: 25px;
    background-color: red;
    border-radius: 50%;
    color: white;
    text-align: center;
    line-height: 25px;
    font-weight: bold;
}
