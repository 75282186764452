.message-count-animate-appear {
    opacity: 0;
    transform: scale(0);
}

    .message-count-animate-appear.message-count-animate-appear-active {
        opacity: 1;
        transform: scale(1);
        transition: transform 200ms, opacity 200ms;
    }

.messenger-launcher .message-count {
    position: absolute;
    top: -10px;
    right: 0;
    width: 25px;
    height: 25px;
    background-color: red;
    border-radius: 50%;
    color: white;
    text-align: center;
    line-height: 25px;
    font-weight: bold;
}

.messenger-launcher.opened .message-count {
    display: none;
}

.messenger-new-messages-count {
    position: absolute;
    top: -3px;
    left: 41px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    background: #ff4646;
    color: white;
    text-align: center;
    margin: auto;
    font-size: 12px;
    font-weight: 500;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.3);
}


.messenger-window {
    z-index: 999;
    width: 650px;
    height: calc(100% - 120px);
    max-height: 590px;
    position: fixed;
    right: 25px;
    bottom: 100px;
    box-sizing: border-box;
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
    border-radius: 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}


    .messenger-window.expanded {
        width: 100%;
        height: 100%;
        position: fixed;
        right: 0;
        bottom: 0;
        max-height: 100%;
    }

    .messenger-window.closed {
        opacity: 0;
        visibility: hidden;
        bottom: 90px;
    }



    .messenger-window .hidden {
        display: none;
    }

@media (max-width: 450px) {
    .messenger-window {
        width: 100%;
        height: 100%;
        max-height: 100%;
        right: 0px;
        bottom: 0px;
        border-radius: 0px;
    }

    .messenger-window {
        transition: 0.1s ease-in-out;
    }

        .messenger-window.closed {
            bottom: 0px;
        }
}


.message-sidebar {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
}



.messenger-header {
    background: #4e8cff;
    color: white;
    position: relative;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    height: 40px;
    min-height: 40px;
}


    .messenger-header span {
        font-size: 16px;
    }

.messenger-header-title {
    padding: 8px;
    flex: 1;
    user-select: none;
    text-transform: uppercase;
}

.messenger-header-close {
    width: 35px;
    height: 36px;
    align-self: center;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    line-height: 34px;
}

    .messenger-header-close:hover {
        background: #4882ed;
    }

.messenger-header-expand {
    width: 100px;
    height: 40px;
    align-self: center;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    line-height: 40px;
}

    .messenger-header-expand:hover {
        background: #4882ed;
    }


.message-list li span.unread {
    font-weight: bold !important;
}



.messenger-message-list {
    padding: 0;
}

    .messenger-message-list .ant-layout-header {
        height: auto !important;
    }

    .messenger-message-list header.info {
        background-color: #FFFFFF;
        border-bottom: 1px solid #f0f0f0;
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
        min-height: 38px;
        padding: 0 !important;
    }

        .messenger-message-list header.info .session-title {
            text-align: left;
            line-height: 25px;
            font-weight: bold;
            padding: 3px 5px 3px 10px;
            display: flex;
            min-height: 50px;
            flex-direction: column;
        }

            .messenger-message-list header.info .session-title div.company-name {
                width: 100%;
                align-self: stretch;
            }


            .messenger-message-list header.info .session-title svg {
                vertical-align: middle;
            }

    .messenger-message-list .header.info .session-title .video {
        padding-right: 5px;
        cursor: pointer;
    }


    .messenger-message-list header.info .session-notifcation {
        text-align: left;
        line-height: 35px;
        font-weight: bold;
        padding: 3px 5px 3px 10px;
        display: flex;
        min-height: 35px;
    }

        .messenger-message-list header.info .session-notifcation .ant-btn {
            padding: 0;
        }

    .messenger-message-list footer {
        min-height: 50px;
        padding: 5px;
        border-top: 1px solid #f0f0f0;
        border-left: 1px solid #f0f0f0;
        /*        background-color: #f4f7f9;*/
        background-color: white;
        position: relative;
        color: #999999;
    }

        .messenger-message-list footer.hidden {
            display: none;
        }

        .messenger-message-list footer.active {
            background-color: white;
            box-shadow: 0px -5px 20px 0px rgba(150, 165, 190, 0.2);
        }

            .messenger-message-list footer.active .user-input-text {
                color: #565867;
            }


        .messenger-message-list footer .user-input-text {
            width: 100%;
            margin-right: 100px;
            resize: none;
            border: none;
            outline: none;
            box-sizing: border-box;
            padding: 10px;
            padding-right: 75px;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.33;
            white-space: pre-wrap;
            word-wrap: break-word;
            -webkit-font-smoothing: antialiased;
            max-height: 400px;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: auto;
            text-align: left;
        }

        .messenger-message-list footer .user-buttons {
            width: 70px;
            position: absolute;
            right: 10px;
            bottom: 10px;
            text-align: right;
        }

        .messenger-message-list footer .icon {
            cursor: pointer;
            align-self: center;
            outline: none;
            opacity: 0.5;
            max-width: 25px;
            margin-left: 8px;
        }

        .messenger-message-list footer input[type="file"] {
            display: none;
        }

        .messenger-message-list footer .icon:hover {
            opacity: 1;
        }

.messenger-message-list-messages {
    padding: 14px;
    margin: 0;
    /*min-height: 280px;*/
    overflow-y: auto;
    background-color: #EBEFF2;
}

    .messenger-message-list-messages .messages {
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
    }

    .messenger-message-list-messages .message {
        /*            border-radius: 16px;*/
        padding: 3px 15px 3px 15px;
        position: relative;
    }

        .messenger-message-list-messages .message span {
            word-break: break-word;
        }

        .messenger-message-list-messages .message a.document {
            /* padding: 5px;
                display: flex;*/
            /*                font-weight: 300;
                font-size: 14px;*/
            cursor: pointer;
            /*                text-decoration: none;*/
        }

            .messenger-message-list-messages .message a.document img {
                padding-left: 10px;
                max-height: 20px;
                opacity: 0.5;
            }


        .messenger-message-list-messages .message .image {
            width: 100%;
            height: auto;
        }


    .messenger-message-list-messages .recipient {
        margin-bottom: 10px;
    }

        .messenger-message-list-messages .recipient time {
            font-size: 11px;
            text-align: left;
            opacity: 0.7;
            flex-direction: column;
            justify-content: flex-start;
            display: block;
        }



        .messenger-message-list-messages .recipient .messages .message {
            background-color: white;
            /*             #f4f4f8;*/
            justify-content: flex-start;
            margin: 0 25% 0 0;
        }

            .messenger-message-list-messages .recipient .messages .message:first-child {
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                padding-top: 15px;
            }

            .messenger-message-list-messages .recipient .messages .message:last-child {
                border-bottom-right-radius: 15px;
                padding-bottom: 15px;
            }

                .messenger-message-list-messages .recipient .messages .message:last-child:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                    left: -7px;
                    height: 20px;
                    width: 20px;
                    background-color: white;
                    border-bottom-right-radius: 15px;
                }

                .messenger-message-list-messages .recipient .messages .message:last-child:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: -10px;
                    width: 10px;
                    height: 20px;
                    background-color: #EBEFF2;
                    border-bottom-right-radius: 10px;
                }

    .messenger-message-list-messages .sender {
        margin-bottom: 10px;
    }

        .messenger-message-list-messages .sender time {
            font-size: 11px;
            text-align: right;
            justify-content: flex-end;
            opacity: 0.7;
            flex-direction: column;
            display: block;
        }

        .messenger-message-list-messages .sender .messages .message {
            background-color: #D0D8DB;
            /*            #007aff;*/
            justify-content: flex-end;
            margin: 0 0 0 25%;
            color: black;
        }

            .messenger-message-list-messages .sender .messages .message:first-child {
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                padding-top: 15px;
            }

            .messenger-message-list-messages .sender .messages .message:last-child {
                border-bottom-left-radius: 15px;
                padding-bottom: 15px;
            }

                .messenger-message-list-messages .sender .messages .message:last-child:before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                    right: -8px;
                    height: 20px;
                    width: 20px;
                    background-color: #D0D8DB;
                    background-attachment: fixed;
                    border-bottom-left-radius: 15px;
                }

                .messenger-message-list-messages .sender .messages .message:last-child:after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    right: -10px;
                    width: 10px;
                    height: 20px;
                    background-color: #EBEFF2;
                    border-bottom-left-radius: 10px;
                }




    .messenger-message-list-messages .info {
        margin-bottom: 10px;
    }

        .messenger-message-list-messages .info time {
            font-size: 11px;
            text-align: right;
            justify-content: flex-end;
            opacity: 0.7;
            flex-direction: column;
            display: block;
        }

        .messenger-message-list-messages .info .messages .message {
            justify-content: flex-end;
            margin: 0;
            color: #666666;
            font-size: 12px;
            background-color: #f9f9f9;
        }

            .messenger-message-list-messages .info .messages .message svg {
                vertical-align: middle;
            }


.messenger-session-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

    .messenger-session-list li {
        margin: 0;
        padding: 0;
        font-size: 13px;
        cursor: pointer;
        text-transform: uppercase;
        padding: 5px;
        border-bottom: 1px solid #eee;
        position: relative;
    }

        .messenger-session-list li .name {
            /*            padding: 4px 0 2px 0;*/
            display: block;
        }

        .messenger-session-list li .vendor-name {
            display: none;
        }



        .messenger-session-list li.vendor-name-visible .vendor-name {
            display: block !important;
            font-size: 11px;
        }

        .messenger-session-list li .broker-name {
            display: none;
        }



        .messenger-session-list li.broker-name-visible .broker-name {
            display: block !important;
            font-size: 11px;
        }

        .messenger-session-list li .schedule {
            display: none;
        }

        .messenger-session-list li.schedule-visible .schedule {
            display: block !important;
            font-size: 11px;
        }


        .messenger-session-list li:hover {
            background-color: #e6f7ff !important;
        }

        .messenger-session-list li.active {
            background-color: #e6f7ff !important;
            display: block !important;
        }

        .messenger-session-list li.unread {
            font-weight: bold;
        }

        .messenger-session-list li div.session {
            padding: 4px 15px 2px 2px;
        }

        .messenger-session-list li div.online {
            flex-direction: column;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: green;
            vertical-align: middle;
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .messenger-session-list li div.more {
            flex-direction: column;
            /*  width: 7px;
            height: 7px;*/
            vertical-align: middle;
            position: absolute;
            right: 5px;
            top: 10px;
        }




.messenger-message-list footer .upload-progress {
    height: 3px;
    background-color: green;
    width: 0%;
}



.messenger-message-list .video {
    min-height: 250px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 !important;
    width: 100%;
    border-left: 1px solid #f0f0f0;
}

    .messenger-message-list .video .video-window {
        position: relative;
        background-color: #fff !important;
        z-index: 9999999;
        padding: 0 !important;
        margin: 0 !important;
    }

        .messenger-message-list .video .video-window main {
            background-color: #fff !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .messenger-message-list .video .video-window aside {
            max-width: 100px;
        }


.messenger-window.expanded .video {
    /*height: auto !important;*/
    /*min-height: 100%;*/
}


.messenger-window.expanded .video-window {
    /*height: auto !important;*/
    height: 100%;
}

    .messenger-window.expanded .video-window aside {
        max-width: 250px;
    }


.messenger-video {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.messenger-video-participant-list {
    max-height: 100px;
    display: flex;
}

    .messenger-video-participant-list .participant {
        max-width: 80px;
        max-height: 60px;
        padding: 2px;
        flex: 1;
    }

        .messenger-video-participant-list .participant video {
            max-width: 80px;
            max-height: 60px;
        }

.messenger-video-main-participant {
    height: 180px;
}

.main-participant-container {
    height: 100%;
    padding-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.messenger-video-main-participant video {
    height: 100%;
    max-height: 100%;
}


.messenger-window.expanded .messenger-video-participant-list video {
    max-width: 200px;
}

.messenger-window.expanded .messenger-video-participant-list {
    max-height: 200px;
}

    .messenger-window.expanded .messenger-video-participant-list .participant {
        max-width: 160px;
        max-height: 100px;
    }
        .messenger-window.expanded .messenger-video-participant-list .participant video {
            max-width: 160px;
            max-height: 100px;
        }

    .messenger-window.expanded .messenger-video-main-participant {
        width: 100%;
        flex: 1 0 auto;
    }

    .messenger-window.expanded .messenger-video-main-participant video {
        max-width: 100%;
        /*height: 100%;*/
        /*max-height: 600px;*/
    }





.meeting-status {
    display: flex;
}

    .meeting-status div {
        /*justify-content: space-between;*/
        font-size: 12px;
    }

.echo-help {
    font-size: 12px;
    color: #999999;
    text-align: right;
    /*    margin-left: auto;
    width:180px;*/
    margin-left: auto;
    order: 2;
}

.echo-help span {
    font-size: 16px;
}


.time-left {
    /*margin-left: auto;*/
    color: green;
    /*width: 70%;*/
}
