.order-window {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.order-header {
    position: relative;
    box-sizing: border-box;
    /*height: 150px;
    min-height: 150px;*/
    /*border-bottom: 1px solid #f0f0f0;*/
}

.order-window .ant-layout {
    background-color: white;
}

.order-window .ant-layout-header {
    background-color: white;
}

.order-window aside {
    border-right: 1px solid #f0f0f0;
}

.vendor-list {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 40px;
}

    .vendor-list li {
        padding: 5px;
        margin: 0;
        font-size: 14px;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        text-transform: uppercase;
    }

        .vendor-list li:hover {
            background-color: #EBEFF2 !important;
        }

        .vendor-list li.visible {
            display: block;
        }

        .vendor-list li.bold {
            background-color: #106CCE !important;
            color: #fff !important;
        }

        .vendor-list li.bold-indent {
            background-color: #57a7ff !important;
            color: #fff !important;
        }

        .vendor-list li.indent {
           padding-left:20px;
        }

.all-vendors {
    position: fixed;
    height: 40px;
    background-color: #fff;
    padding: 5px;
    margin: 0;
    width: 234px;
    cursor: pointer;
    /*    border-bottom: 2px solid #eee;*/
}


.order-window .order-header {
    height: 50px !important;
    padding: 0 0 0 5px !important;
    background-color: #FFFFFF !important;
    text-align: left !important;
    z-index:500;
}

.order-window footer {
    height: 41px;
    padding: 3px;
    background-color: #FFFFFF;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    z-index: 998;
    border-top: 1px solid #d9d9d9;
    margin-right: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

    .order-window footer .item-count {
        padding-left: 5px;
        max-width: 150px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
    }

    .order-window footer .order-status {
        flex: 1;
        justify-content: center;
        align-items: center;
    }

    .order-window footer .order-notification {
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        max-width: 140px;
        text-align: right;
        padding-right: 5px;
        color: forestgreen;
    }

        .order-window footer .order-notification div {
            transition: 500ms all ease;
            opacity: 0;
            will-change: opacity;
        }


@keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
        opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
        opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
        opacity: .2;
    }
}

.order-window footer .order-notification span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

    .order-window footer .order-notification span:nth-child(2) {
        animation-delay: .2s;
    }

    .order-window footer .order-notification span:nth-child(3) {
        animation-delay: .4s;
    }


.show {
    opacity: 1 !important;
}

.vendor-name-header {
    background-color: #f0f0f0 !important;
    height: 40px;
    padding: 5px;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    display: flex;
}


    .vendor-name-header span {
        padding-right: 5px;
    }

        .vendor-name-header span.vendor-name {
            padding-right: 30px;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
        }


    .vendor-name-header svg {
        max-height: 25px;
        max-width: 25px;
        opacity: 0.5;
        vertical-align: middle;
        cursor: pointer;
    }

    .vendor-name-header:hover svg {
        opacity: 1;
    }

.item-name-header {
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

    .item-name-header img {
        margin-left: 5px;
        max-height: 25px;
        max-width: 25px;
        opacity: 0.5;
    }

    .item-name-header:hover img {
        opacity: 1;
    }



#grid-filters {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
}

    #grid-filters .filters {
        flex-grow: 1;
        display: flex;
        align-items: center;
        line-height: 50px;
    }

        #grid-filters .filters .filter-item {
            padding-right: 5px;
            flex-direction: column;
            align-items: center;
        }

    #grid-filters .submit {
        margin-left: auto;
        flex-direction: column;
        align-items: center;
        line-height: 40px;
    }

    #grid-filters .customer-name div {
    }

    #grid-filters .customer-name svg {
        margin-left: 5px;
        height: 25px;
        width: 25px;
        opacity: 0.5;
    }

    #grid-filters .customer-name:hover svg {
        opacity: 1;
    }


    #grid-filters .customer-name {
        cursor: pointer;
        padding-right: 5px;
    }

        #grid-filters .customer-name svg {
            vertical-align: middle;
            opacity: 0.5;
            height: 25px;
            width: 25px
        }

    #grid-filters .submit .customer-name:hover svg {
        opacity: 1;
    }

    #grid-filters .filter .filter-item .ant-select {
        display: block;
        margin-top: 2px;
    }

    #grid-filters .filter .filter-item .ant-btn-link {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }


#order-show-info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
}


    #order-show-info .logo {
    }


    #order-show-info .info {
        line-height: 50px;
        font-size: 16px;
    }


    #order-show-info .ant-badge-count {
        background-color: #52c41a;
    }

    #order-show-info .info button {
        padding: 4px 8px;
    }

    #order-show-info .info .separator {
        opacity: 0.2;
    }


#company-logo {
    cursor: pointer;
}

    #company-logo img {
        max-height: 50px;
    }

    #company-logo span {
        padding-left: 10px;
    }

    #company-logo a {
        font-size: 12px;
        text-decoration: none;
    }

 
#customer-name {
    /*    padding-left: 10px;*/
    padding-right: 10px;
}


.order-clear {
    text-align: center;
    cursor: pointer;
    width: 50px;
    height: 30px;
    line-height: 30px;
    color: #106CCE !important;
}

/*.slick-slider{
    max-height:50px;
}*/

.vendor-slider {
    height: 50px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px solid #f0f0f0;
    z-index:500;
}

/*   .vendor-slider li.alice-carousel__stage-item:not(.__cloned) {
        width: auto !important;
    }*/

.swiper-slide {
    text-align: center;
}

.slider-img {
    cursor: pointer;
    max-height: 40px;
    max-width: 100px;
    min-height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

img.swiper-lazy-loading {
    z-index: 1 !important;
}

.swiper-lazy-preloader {
    z-index: 2 !important;
    background-color: white !important;
}

.swiper-slide {
}

.order-summary {
    margin: 10px;
}


#order-table .ant-badge-count {
    right: -15px;
    margin-top: -5px;
    background-color: #52c41a;
}


.video-link {
    cursor: pointer;
}

    .video-link img {
        max-height: 80px;
        max-width: 80px;
        padding-right: 10px;
    }



.image-link {
    cursor: pointer;
}

    .image-link img {
        max-height: 80px;
        max-width: 80px;
        padding-right: 10px;
    }

img.item-image {
    max-width: 50px;
    max-height: 50px;
    cursor: pointer;
}


.contract-link {
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
}

    .contract-link span {
        font-size: 24px;
        padding-right: 10px;
    }





.ant-input-disabled.order-quantity {
    background-color: none !important;
    color: black !important;
    border: none !important;
}


.min-max-warning {
    color: red;
    font-size: 18px;
    padding: 5px;
}

.signature-canvas {
    border: 1px solid #d9d9d9;
}




.contract-file {
}

.contract-controls {
    width: 30%;
    overflow-y: scroll;
    padding: 10px;
}

.w-100p {
    width: 100%;
}

.w-70p {
    width: 70%;
}

.w-30p {
    width: 30%;
}

.contract-inputs {
    display: flex;
    flex-direction: column;
}

.contract-signature {
    padding-top: 35px;
}

.contract-stores {
}

.contract-notes {
    padding-bottom: 40px;
}

.contract-submit-top {
    position: absolute;
    top: 50px;
    height: 35px;
    z-index: 998;
}

.contract-submit-bottom {
    position: absolute;
    bottom: 0;
    height: 40px;
    z-index: 998;
}


.signature-title {
    display: flex;
    justify-content: space-between;
}

    .signature-title h2 {
        /*justify-content: start;*/
    }

    .signature-title button {
        /*justify-content: flex-end;*/
    }


.headline-link {
    font-size: 18px;
}

.document-link {
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
}

    .document-link span {
        font-size: 24px;
        padding-right: 10px;
    }


.vendor-logo {
    max-height: 200px;
    max-width: 200px
}


.right-aligned {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
}

.right-aligned {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
}
