
.layout {
    margin-top: 70px;
}


.content {
    padding: 0 10px 10px 10px;
    margin: auto;
    margin-top: 5px;
    width: 100%;
}

    .content img {
        max-width: 100px;
        max-height: 100px;
    }

    .content .ant-skeleton-image {
        width: 72px;
        height: 72px;
        line-height: 72px;
    }


    .content > li {
        border-bottom: 1px solid #eee;
        list-style-type: none;
        margin: 0;
        padding: 10px;
        display: flex;
        width: 100%;
    }

        .content > li:hover {
            background-color: #e6f7ff !important;
        }


.header {
    text-align: left;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
}

.filter {
    flex:1;
}

.count {
    text-align: right;
}

.item {
    border-bottom: 1px solid #eee;
    list-style-type: none;
    margin: 0;
    padding: 10px;
    display: flex;
    width: 100%;
    min-height: 128px;
}