.container {
    width: 100%;
}

.header {
    width: 100%;
    display: flex;
    min-height: 37px;
    max-height: 37px;
    font-weight: bold;
    padding-right: 18px;
    border-bottom: 1px solid #d9d9d9;
}

    .header .generic {
        padding: 6px;
        line-height: 23px !important;
        display: flex;
        flex-direction: column;
        flex-basis: 20%;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

        .header .generic span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }


    .header .quantity {
        border: none !important;
        padding: 6px;
        line-height: 23px !important;
        display: flex;
        flex-direction: column;
        /*        flex-basis: 20%;
        flex: 1;*/
        width: 90px !important;
        overflow: hidden;
        text-align: center;
    }

    .header .x2 {
        flex-basis: 30%;
    }

    .header .history {
        max-width: 90px !important;
    }

    .header .itemno {
        min-width: 70px;
    }


.content {
    width: 100%;
    height: 100%;
    /*min-height: 300px;*/
    /*display: flex;*/
    flex: 1;
}

.footer {
    height: 100px;
}
