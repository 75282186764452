.header {
    display: flex;
    width: 100%;
    background-color: #f0f0f0 !important;
    height: 51px;
    padding: 5px;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    border-right: 1px solid #d9d9d9;
}

    .header span {
        padding-right: 5px;
    }

.name {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    padding-right: 30px !important;
    padding-left:10px !important;
    opacity:0.8;
}



.header svg {
    max-height: 25px;
    max-width: 25px;
    opacity: 0.5;
    vertical-align: middle;
    cursor: pointer;
}

.header:hover svg {
    opacity: 1;
}

.total {
    margin-left: auto;
}


.img {
    max-height: 45px;
    max-width: 45px;
}
