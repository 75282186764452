.row {
    width: 100%;
    /*height: 63px;*/
}

    .row:hover {
        background-color: #EBEFF2 !important;
    }

.selected {
}

.columns {
    display: flex;
}

.empty {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: 50px;
}


.generic {
    padding: 6px;
    line-height: 30px !important;
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    flex: 1;
    overflow: hidden;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.x2 {
    flex-basis: 30%;
}

.itemno{
    min-width:70px;
}

.history {
    max-width: 90px !important;
    text-align: center;
}

.center {
    text-align: center;
}


.quantity {
    /*border: none !important;*/
    padding: 6px;
    line-height: 30px !important;
    display: flex;
    flex-direction: column;
    /*flex-basis: 20%;*/
    width: 90px !important;
    /*flex: 1;*/
    overflow: hidden;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
}


.orderquantity {
    width: 58px;
    height: 38px;
    text-align: center;
    border: solid 1px rgb(217, 217, 217);
    background-color: #fff;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.even {
    background-color: #f9f9f9 !important;
}


.shipper {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px 5px 5px 10px;
    font-size: 12px;
}
